import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import PrivateRoute from "../utils/PrivateRoute";
import RouteAfterLogin from "../utils/RouteAfterLogin";
import OnlyCreator from "../utils/OnlyCreator";
import OnlyConsumer from "../utils/OnlyConsumer";
import IsSubscribed from "../utils/IsSubscribed";
import IsChatAllowed from "../utils/IsChatAllowed";

import SplashScreen from "../screens/SplashScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import BenefitScreen from "../screens/Benefit";
import ConsumerBenefitScreen from "../screens/ConsumerBenefit";
import Registration from "../screens/auth/Registration";
import ResetPassword from "../screens/auth/ResetPassword";
import ForgotPassword from "../screens/auth/ForgotPassword";
import RegistrationSuccess from "../screens/auth/RegistrationSuccess";
import AgeVerification from "../screens/auth/AgeVerification";
import OtpVerification from "../screens/auth/OtpVerification";
import Maintenance from "../screens/Maintenance";

//Creator
import Setup from "../screens/creator/profile/Setup";
import Setup3 from "../screens/creator/profile/Setup3";
import FolderList from "../screens/creator/myfolder/FolderLists";
import FolderFiles from "../screens/creator/myfolder/FolderFiles";
import UploadContent from "../screens/creator/myfolder/UploadContent";
import MyProfile from "../screens/creator/profile/MyProfile";
import StateExclusionList from "../screens/creator/profile/StateExclusionList";
import PicVideos from "../screens/creator/myfolder/PicandVideos";
import ProfileImages from "../screens/creator/profile/ProfileImages";
import AutomatedMessage from "../screens/creator/profile/AutomatedMessage";
import PrivateIntroductoryFolder from "../screens/creator/profile/PrivateIntroductoryFolder";
import WithdrawAmount from "../screens/creator/wallet/WithdrawAmount";
import WithdrawAmountPage from "../screens/creator/wallet/WithdrawAmountPage";
import WithdrawalRequests from "../screens/creator/wallet/WithdrawalRequests";

//Consumer
import ConsumerFolderList from "../screens/consumer/myFolder/FolderList";
import ConsumerFiles from "../screens/consumer/myFolder/FolderFiles";
import Search from "../screens/creator/search/Search";
import CreatorProfile from "../screens/creator/search/CreatorProfile";

import MyAccount from "../screens/consumer/profile/MyAcccount";
import EditProfile from "../screens/consumer/profile/EditProfile";
import Faourites from "../screens/consumer/profile/Faourites";
import ConsMyWallet from "../screens/consumer/wallet/MyWallet";
import AddMoneyWallet from "../screens/consumer/wallet/AddMoneyWallet";
import ManageCard from "../screens/consumer/wallet/ManageCard";
import AddNewCard from "../screens/consumer/wallet/AddNewCard";
import SubscribeNow from "../screens/ConsumerOnboarding";

import ComingSoon from "../screens/ComingSoon";

import ManageBillingAddress from "../screens/consumer/billingAddress/ManageBillingAddress";
import AddNewBillingAddress from "../screens/consumer/billingAddress/AddNewBillingAddress";
import { Navigate } from 'react-router-dom';
//Chat
import ChatList from "../screens/chat/ChatList";
import ChatInformation from "../screens/chat/ChatInformation";
import ChatMessage from "../screens/chat/ChatMessage";
import AddBank from "../screens/creator/wallet/AddBank";
import ManageBanks from "../screens/creator/wallet/ManageBanks";
import EditBank from "../screens/creator/wallet/EditBank";
import AddAddress from "../screens/creator/wallet/AddAddress";
import EditAddress from "../screens/creator/wallet/EditAddress";
import ManageAddresses from "../screens/creator/wallet/ManageAddresses";
import Settings from "../screens/settings/Settings";
import ChangePassword from "../screens/settings/ChangePassword";
import MyInformationSettings from "../screens/settings/MyInformationSettings";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import TermsAndConditions from "../screens/TermsAndConditions";
import About from "../screens/About";
import Help from "../screens/Help";
import ManageSubscriptions from "../screens/subscription/ManageSubscriptions";
import PaymentHistory from "../screens/subscription/PaymentHistory";

//Reports
import ReportsHome from "../screens/creator/reports/ReportsHome";
import PurchasedRequests from "../screens/creator/reports/PurchasedRequests";
import EarningsList from "../screens/creator/reports/EarningsList";
import Unsubscribed from "../screens/Unsubscribed";
import MonthlyEarningsList from "../screens/creator/reports/MonthlyEarningsList";

import AppLogout from "../components/AppLogout";
import Complaints from "../screens/Complaints";
import ChooseCard from "../screens/subscription/ChooseCard";

const AppRouter = () => {

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null; // This component doesn't render anything
  };

  const underMaintenance = true;
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        // position="bottom-center"
        autoClose={3000}
        hideProgressBar
        draggable={false}
        limit={1}
        role="alert"
        icon={false}
        theme="dark"
        transition={Flip}
        style={{
          marginTop: "30px",
          marginLeft: "30px",
          maxWidth: "300px",
          height: "30px",
        }}
      />
      <ScrollToTop />
      <Routes>
        {underMaintenance ? (
          <>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Maintenance />} />
          </>
        ) : (
        <>
        <Route
          path="/"
          element={
            <RouteAfterLogin>
              <SplashScreen />
            </RouteAfterLogin>
          }
        />
        <Route
          path="login"
          element={
            <RouteAfterLogin>
              <LoginScreen />
            </RouteAfterLogin>
          }
        />
        <Route
          path="otp-verification"
          element={
            <RouteAfterLogin>
              <OtpVerification />
            </RouteAfterLogin>
          }
        />
        <Route
          path="register"
          element={
            <RouteAfterLogin>
              <Registration />
            </RouteAfterLogin>
          }
        />

        <Route
          path="age-verification"
          element={
            <RouteAfterLogin>
              <AgeVerification />
            </RouteAfterLogin>
          }
        />

        <Route
          path="benefits"
          element={
            <RouteAfterLogin>
              <BenefitScreen />
            </RouteAfterLogin>
          }
        />
        <Route
          path="consumerBenefits"
          element={
            <RouteAfterLogin>
              <ConsumerBenefitScreen />
            </RouteAfterLogin>
          }
        />
        <Route
          path="resetPassword"
          element={
            <RouteAfterLogin>
              <ResetPassword />
            </RouteAfterLogin>
          }
        />
        <Route
          path="forgotPassword"
          element={
            <RouteAfterLogin>
              <ForgotPassword />
            </RouteAfterLogin>
          }
        />
        <Route
          path="creator/folder"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <FolderList />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/folder_files"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <FolderFiles />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/upload_files"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <UploadContent />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/:folderName/folder-files"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <FolderFiles />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/:folderName/upload-files"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <UploadContent />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/profile/:id"
          element={
            <AppLogout>
                <OnlyConsumer>
                  <CreatorProfile />
                </OnlyConsumer>
            </AppLogout>
          }
        />

        <Route
          path="setup"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <Setup />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="setup3"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <Setup3 />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="reports"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <ReportsHome />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="reports/monthly-earning"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <MonthlyEarningsList />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="requests"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <PurchasedRequests />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="earnings"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <EarningsList />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="registrationSuccess"
          element={
            <AppLogout>
              <PrivateRoute>
                <RegistrationSuccess />
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="subscribe"
          element={
            <AppLogout>
              <PrivateRoute>
                {/* <RouteAfterLogin> */}
                <SubscribeNow />
                {/* </RouteAfterLogin> */}
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="consumer/folder"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                    <ConsumerFolderList />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/files"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                    <ConsumerFiles />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="payment"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <ChooseCard />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/profile"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                    <MyAccount />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="consumer/profile/edit"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <EditProfile />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/favorites"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                    <Faourites />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="wallet"
          element={
            <AppLogout>
              <PrivateRoute>
                <ConsMyWallet />
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/addmoney"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <AddMoneyWallet />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="consumer/cards"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <ManageCard />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/add_card"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <AddNewCard />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/billing_address"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <ManageBillingAddress />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/add_billing_address"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <AddNewBillingAddress />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="consumer/search"
          element={
            <AppLogout>
                <OnlyConsumer>
                    <Search />
                </OnlyConsumer>
            </AppLogout>
          }
        />
        {/* <Route
          path="consumer/settings"
          element={
            <AppLogout>
            <PrivateRoute>
              <OnlyConsumer>
                <ConsumerSettings />
              </OnlyConsumer>
            </PrivateRoute>
            </AppLogout>
          }
        /> */}
        <Route
          path="chat"
          element={
            <AppLogout>
              <PrivateRoute>
                <IsChatAllowed>
                  {/* <RouteAfterLogin> */}
                  <ChatList />
                </IsChatAllowed>
                {/* </RouteAfterLogin> */}
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="chat/message"
          element={
            <AppLogout>
              <PrivateRoute>
                <IsChatAllowed>
                  {/* <RouteAfterLogin> */}
                  <ChatMessage />
                </IsChatAllowed>
                {/* </RouteAfterLogin> */}
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="chat/information"
          element={
            <AppLogout>
              <PrivateRoute>
                <IsChatAllowed>
                  {/* <RouteAfterLogin> */}
                  <ChatInformation />
                </IsChatAllowed>
                {/* </RouteAfterLogin> */}
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/account"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <MyProfile />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/stateExclussion"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <StateExclusionList />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/pictureandvideo"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <PicVideos />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/automated-message"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <AutomatedMessage />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/profileimages"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <ProfileImages />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/privateintroductoryfolder"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <PrivateIntroductoryFolder />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/myinformation"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <MyInformationSettings />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/walletwithdraw"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <WithdrawAmount />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/walletwithdraw_page"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <WithdrawAmountPage />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/withdrawal_request"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <WithdrawalRequests />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        {/* <Route
          path="creator/banks"
          element={
            <AppLogout>
            <PrivateRoute>
              <OnlyCreator>
                <Banks />
              </OnlyCreator>
            </PrivateRoute>
            </AppLogout>
          }
        /> */}
        <Route
          path="creator/addbank"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <AddBank />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
         <Route
          path="creator/editbank/:id" 
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <EditBank />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/managebank"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <ManageBanks />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/addaddress"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <AddAddress />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
         <Route
          path="creator/editaddress/:id"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <EditAddress />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="creator/manageaddress"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyCreator>
                  <ManageAddresses />
                </OnlyCreator>
              </PrivateRoute>
            </AppLogout>
          }
        />

        <Route
          path="settings"
          element={
            <AppLogout>
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="changepassword"
          element={
            <AppLogout>
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="subscription"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <ManageSubscriptions />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="payment_history"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <PaymentHistory />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        <Route
          path="unsubscribed"
          element={
            <AppLogout>
              <PrivateRoute>
                <OnlyConsumer>
                  <Unsubscribed />
                </OnlyConsumer>
              </PrivateRoute>
            </AppLogout>
          }
        />
        {/* <Route path="about" element={<About />} /> */}
        <Route path="refund_policy" element={<Help />} />
        <Route path="2257_statement" element={<PrivacyPolicy />} />
        <Route path="complaints" element={<Complaints />} />
        <Route path="terms_conditions" element={<TermsAndConditions />} />
        </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
