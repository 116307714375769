import { Images } from "../themes";
const Maintenance = () => {
  return (
    <div className="wrapper splash main-content">
      <div className="container">
        <div className="splash-wrap maintenance">
          <div className="row">	
            <div className="col-12 left-block">
              <figure>
                <img src={Images.coming_soon_logo} />
              </figure>
            </div>
            <div className="col-12 right-block">
              <div className="main-inner">
                <div className="txt-format">
                  <h2>Arouzed.com Announcement
                  </h2>
                  <div className="my-ac-form-info">
                    <div className="form-inner-holder">
                    <h3 >Dear Creators and Members,</h3>
                  <h3 >We want to inform you that the Arouzed.com platform has been acquired. As part of this acquisition, the platform will be permanently closed. </h3>
                  <h3 >Refunds and payouts will be processed via escrow. For further assistance, please contact <a href="mailto:support@arouzed.com">support@arouzed.com</a>.</h3>
                    </div>

                  </div>
                 
                  </div>
                <div className="txt-format">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
